import { t } from '@lingui/macro'

export enum TradeBuyOrSellEnum {
  buy = '0',
  sell = '1',
}

/** 交易页面布局类型 */
export enum TradeLayoutEnum {
  default = 'default',
  left = 'left',
  right = 'right',
}

/** 市价交易交易类型 */
export enum TradeMarketAmountTypesEnum {
  amount = 'amount',
  funds = 'funds',
}

export const getTradeMarketAmountTypesMap = (funds, amount) => ({
  [TradeMarketAmountTypesEnum.amount]: amount,
  [TradeMarketAmountTypesEnum.funds]: funds,
})

/** 杠杆交易类型 */
export enum TradeMarginTypesEnum {
  /** 普通 */
  normal = 'normal',
  /** 自动借款 */
  borrow = 'borrow',
  /** 自动还款 */
  repay = 'repay',
}

/** 杠杆交易类型 */
export const getTradeMarginTypesMap = () => ({
  [TradeMarginTypesEnum.normal]: t`constants_trade_7`,
  [TradeMarginTypesEnum.borrow]: t`constants_trade_8`,
  [TradeMarginTypesEnum.repay]: t`constants_trade_9`,
})

/** 交易类型 */
export enum TradeModeEnum {
  /** 现货交易 */
  spot = 'spot',
  /** 合约交易 */
  futures = 'futures',
  /** 杠杆交易 */
  margin = 'margin',
}

export const getTradeModeMap = () => {
  return {
    [TradeModeEnum.spot]: t`trade.type.coin`,
    [TradeModeEnum.futures]: t`constants/trade-0`,
    [TradeModeEnum.margin]: t`constants/trade-1`,
  }
}

/** 交易下单类型 */
export enum TradeOrderTypesEnum {
  /** 市价交易 */
  market = 'market',
  /** 限价交易 */
  limit = 'limit',
  /** 计划委托 */
  trailing = 'trailing',
  /** 止盈止损 */
  stop = 'stop',
}

export const getTradeOrderTypesMap = () => {
  return {
    [TradeOrderTypesEnum.market]: t`constants/trade-3`,
    [TradeOrderTypesEnum.limit]: t`constants/trade-2`,
    [TradeOrderTypesEnum.trailing]: t`features_trade_trade_setting_index_2520`,
    [TradeOrderTypesEnum.stop]: t`order.tabs.profitLoss`,
  }
}

export enum TradePriceTypesEnum {
  /** 普通委托 */
  price = 'price',
  /** 市价委托 */
  priceText = 'priceText',
  /** 止盈普通委托 */
  takeProfitPrice = 'takeProfitPrice',
  /** 止损普通委托 */
  stopLossPrice = 'stopLossPrice',
  /** 止盈市价委托 */
  takeProfitPriceText = 'takeProfitPriceText',
  /** 止损市价委托 */
  stopLossPriceText = 'stopLossPriceText',
}

export enum TradePriceTypeEnum {
  /** 币种类型 */
  coinType = '0',
  /** 对手价 */
  lastPrice = 'BBO',
  /** 最优五档 */
  fivePrice = 'optimal5',
  /** 最优十档 */
  tenPrice = 'optimal10',
  /** 最优二十档 */
  twentyPrice = 'optimal20',
}

export const getTradePriceTypeMap = inputSuffix => ({
  [TradePriceTypeEnum.coinType]: inputSuffix,
  [TradePriceTypeEnum.lastPrice]: t`trade.form.price.type.2`,
  [TradePriceTypeEnum.fivePrice]: t`trade.form.price.type.3`,
  [TradePriceTypeEnum.tenPrice]: t`trade.form.price.type.4`,
  [TradePriceTypeEnum.twentyPrice]: t`trade.form.price.type.5`,
})

export const getTradePriceTypeLabelMap = isBuy => {
  if (isBuy) {
    return {
      [TradePriceTypeEnum.lastPrice]: t`trade.form.price.type.label.lastPrice.1`,
      [TradePriceTypeEnum.fivePrice]: t`trade.form.price.type.label.3${5}`,
      [TradePriceTypeEnum.tenPrice]: t`trade.form.price.type.label.3${10}`,
      [TradePriceTypeEnum.twentyPrice]: t`trade.form.price.type.label.3${20}`,
    }
  }
  return {
    [TradePriceTypeEnum.lastPrice]: t`trade.form.price.type.label.lastPrice.2`,
    [TradePriceTypeEnum.fivePrice]: t`trade.form.price.type.label.3${5}`,
    [TradePriceTypeEnum.tenPrice]: t`trade.form.price.type.label.3${10}`,
    [TradePriceTypeEnum.twentyPrice]: t`trade.form.price.type.label.3${20}`,
  }
}

/** 手续费页面 tab 类型 */
export enum HelpFeeTabTypeEnum {
  /** 充提手续费率 */
  withdrawFee = 1,
  /** 现货手续费率 */
  spotFee,
  /** 合约交易手续费率 */
  futuresFee,
}

export type TradeEntrustModalType = Record<'openModal' | 'closeModal', () => void>

export enum TradeMarginEnum {
  /** 逐仓 */
  isolated = 'isolated',
  /** 全仓 */
  margin = 'margin',
}

export const getTradeTabsMap = () => {
  return {
    [TradeModeEnum.spot]: t`trade.type.coin`,
    [TradeMarginEnum.isolated]: t`constants_trade_5`,
    [TradeMarginEnum.margin]: t`constants_trade_6`,
  }
}
