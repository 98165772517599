import { t } from '@lingui/macro'

/**
 * 跟单设置
 */
export enum CopyTradingSettingsTabsEnum {
  /** 完全跟随 */
  followCompletely = 'followCompletely',
  /** 自由跟随 */
  followFree = 'followFree',
  /** 我的策略 */
  myStrategy = 'myStrategy',
}
/**
 * 交易员跟单策略
 */
export enum positionTypeEnum {
  /** 固定金额 */
  fixed = 'fixed',
  /** 固定倍率 */
  rate = 'rate',
}

/**
 * 跟随策略
 */
export enum CopyTradingSettingsFollowStrategyEnum {
  /** 完全跟随 */
  full = 'all_follow',
  /** 自由跟随 */
  free = 'auto_follow',
  /** 我的策略 */
  my = 'self_setting',
}

/**
 * 跟随策略名称
 */
export const getStrategyTypeListEnumMap = () => {
  return {
    [CopyTradingSettingsFollowStrategyEnum.full]: t`constants_copy_trading_settings_mv_9vhj5ha`,
    [CopyTradingSettingsFollowStrategyEnum.free]: t`constants_copy_trading_settings_wu1andyljf`,
    [CopyTradingSettingsFollowStrategyEnum.my]: t`constants_copy_trading_settings_nt6h0zqpb7`,
  }
}

/**
 * 资金比例类型
 */
export enum CopyTradingSettingsFundRatioEnum {
  /** 按跟单账户总资产比例 */
  total = 'account_total_rate',
  /** 按跟单账户可用比例 */
  available = 'account_available_rate',
}

/**
 * 跟单比例设置
 */
export enum CopyTradingAccountRateEnum {
  /** 跟单账户总资产比例 */
  accountTotalRate = 'account_total_rate',
  /** 跟单账户可用比例 */
  accountAvailableRate = 'account_available_rate',
}

export const getAccountRateEnumMap = () => {
  return {
    [CopyTradingAccountRateEnum.accountTotalRate]: t`constants_copy_trading_settings_3pijikvari`,
    [CopyTradingAccountRateEnum.accountAvailableRate]: t`constants_copy_trading_settings_ygcfypzwf5`,
  }
}

/**
 * 杠杆类型
 */
export enum CopyTradingSettingsLeverageEnum {
  /** 高级杠杆设置 */
  advanced = 'prime_lever',
  /** 指定杠杆 */
  specify = 'self_lever',
  /** 跟随交易员 */
  follow = 'follow_tacker',
}

/**
 * 杠杆类型名称
 */
export const getCopyTradingSettingsLeverageName = type => {
  return {
    [CopyTradingSettingsLeverageEnum.advanced]: t`constants_copy_trading_settings_zmbanxemlk`,
    [CopyTradingSettingsLeverageEnum.specify]: t`constants_copy_trading_settings_iqlamaq6kq`,
    [CopyTradingSettingsLeverageEnum.follow]: t`constants_copy_trading_settings_n9krcvqfxf`,
  }[type]
}

/** 策略列表 - 新增、编辑杠杆类型 */
export const getAccountLeverTypeEnumMap = () => {
  return {
    [CopyTradingSettingsLeverageEnum.advanced]: t`constants_copy_trading_settings_zmbanxemlk`,
    [CopyTradingSettingsLeverageEnum.specify]: t`constants_copy_trading_settings_iqlamaq6kq`,
    [CopyTradingSettingsLeverageEnum.follow]: t`constants_copy_trading_settings_n9krcvqfxf`,
  }
}

/** 完全跟随杠杆类型 */
export const getAccountLeverTypeFull = () => {
  return {
    [CopyTradingSettingsLeverageEnum.follow]: t`constants_copy_trading_settings_n9krcvqfxf`,
  }
}

/** 自由跟随杠杆类型 */
export const getAccountLeverTypeFree = () => {
  return {
    [CopyTradingSettingsLeverageEnum.advanced]: t`constants_copy_trading_settings_zmbanxemlk`,
    [CopyTradingSettingsLeverageEnum.specify]: t`constants_copy_trading_settings_iqlamaq6kq`,
  }
}

/**
 * 是否划转跟随交易员
 */
export enum CopyTradingSettingsTransferEnum {
  /** 是 */
  yes = 'Y',
  /** 否 */
  no = 'N',
}

/** 开仓额度类型 */
export enum CopyTradingSettingsOpenPositionEnum {
  /** 固定额度 */
  fixed = 'fixed',
  /** 倍率 */
  rate = 'rate',
}

export const getOpenPositionEnumMap = () => {
  return {
    [CopyTradingSettingsOpenPositionEnum.fixed]: t`constants_copy_trading_settings_xmlrspy78n`,
    [CopyTradingSettingsOpenPositionEnum.rate]: t`constants_copy_trading_settings_ipmmvreboc`,
  }
}

/** 跟单 - 弹窗标题枚举 * */
export enum PopTitleEnumeration {
  /** 一键移除 */
  oneRemove = '1',
  /** 移除跟随者 */
  removeFollowers = '2',
  /** 一键通过 */
  passOne = '3',
  /** 通过 */
  pass = '4',
  /** 拒绝 */
  reject = '5',
}

/** 跟单 - 提示弹窗枚举值 * */
export enum FollowButton {
  /** 空位提醒 */
  vacancyReminder = 1,
  /** 取消跟随 */
  unFollow = 2,
  /** 申请跟随  */
  applyToFollow = 3,
}

/** 跟单 - 跟随状态枚举值 */
export enum followEnum {
  /** 历史跟随 */
  history = 'history',
  /** 当前跟随 */
  current = 'current',
}

/** 带单 - 排序枚举值 */
export enum sortEnum {
  /** 全部 */
  all = 1,
  /** 反序排列 */
  desc = 2,
  /** 正序排列 */
  asc = 3,
}

/** 带单 - 排序枚举值 */
export enum sortTextEnum {
  /** 全部 */
  all = 'all',
  /** 反序排列 */
  desc = 'desc',
  /** 正序排列 */
  asc = 'asc',
}

// 带单 - 排序字段名枚举值
export enum orderByTextEnum {
  /** 跟随时间	 */
  followTime = 'followTime',
  /** 跟随收益	 */
  sumEarnings = 'sumEarnings',
  /** 跟随资产	 */
  followTotalAssets = 'followTotalAssets',
  /** 申请时间 */
  reviewTime = 'reviewTime',
}

/** 带单跟随 - 通过状态枚举 */
export enum viaStatusEnum {
  /** 通过 */
  following = 'following',
  /** 拒绝申请 */
  refuse = 'refuse',
}
