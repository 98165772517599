import { checkUserWithdraw, getCoinBalance, getWithdrawAddressList } from '@/apis/assets/main'
import { baseAssetsStore, defaultCoinAsset } from '@/store/assets'
import { baseMarketStore } from '@/store/market'
import { ICoinBalanceDataList, IUserAssetsSpot } from '@/typings/api/assets/assets'
import { Message } from '@nbit/arco'
import { AssetsScenesTypeEnum } from '@/constants/assets'
import { formatCoinAmount } from './common'

/**
 * 根据常用提币地址
 */
export const getWithdrawAddress = async () => {
  const res = await getWithdrawAddressList({})
  let results = res.data?.addressList
  if (res.isOk && results) {
    return results
  }
  return null
}

/**
 * 提币前校验
 */
export const verifyUserWithdraw = async () => {
  const res = await checkUserWithdraw({})
  const {
    isOk = false,
    data: { errMsg = '', isOpenSafeVerify = false, isSuccess = false } = {},
    message = '',
  } = res || {}

  // 接口层面错误
  if (!isOk) {
    // Message.error(message)
    return { isOk, isSuccess, isOpenSafeVerify }
  }
  if (!isSuccess) {
    // 未开启两项验证，跳转用户中心两项验证页面
    if (!isOpenSafeVerify) {
      const assetsStore = baseAssetsStore.getState()
      assetsStore.updateIsOpenSafeVerify(isOpenSafeVerify)
      return { isOk, isSuccess, isOpenSafeVerify }
    }

    // 其他错误信息，直接弹框提示
    Message.error(errMsg)
    return { isOk, isSuccess, isOpenSafeVerify }
  }

  return { isOk, isSuccess, isOpenSafeVerify }
}

/** 获取现货资产 - 交易页面用 */
export const getUserAssetsSpot = async (options: IUserAssetsSpot) => {
  const currentCoin = baseMarketStore.getState().currentCoin
  const { buyCoinId, sellCoinId } = currentCoin // options
  //  后端要求 coinId 入参类型为数组，get 请求数组会有问题，和后端约定转成字符串类型
  const params = { coinId: `${[buyCoinId, sellCoinId]}`, scenes: AssetsScenesTypeEnum.spotOrder }
  const res = await getCoinBalance(params)
  const { isOk, data } = res || {}

  // 接口异常，或未返回数据时
  if (!isOk || !data || !data.list || data.list.length < 2) {
    return {
      buyCoin: {
        ...defaultCoinAsset,
        coinId: buyCoinId,
      },
      sellCoin: {
        ...defaultCoinAsset,
        coinId: sellCoinId,
      },
    }
  }

  const dataList: ICoinBalanceDataList[] = data.list
  const buyCoinRes = dataList.filter(item => {
    return `${item.coinId}` === `${buyCoinId}`
  })[0]
  const sellCoinRes = dataList.filter(item => {
    return `${item.coinId}` === `${sellCoinId}`
  })[0]
  const userAssets = {
    buyCoin: {
      ...buyCoinRes,
      totalAmount: formatCoinAmount(buyCoinRes.symbol, buyCoinRes.totalAmount, false),
      availableAmount: formatCoinAmount(buyCoinRes.symbol, buyCoinRes.availableAmount, false),
      lockAmount: formatCoinAmount(buyCoinRes.symbol, buyCoinRes.lockAmount, false),
      positionAmount: formatCoinAmount(buyCoinRes.symbol, buyCoinRes.positionAmount, false),
      totalAmountText: formatCoinAmount(buyCoinRes.symbol, buyCoinRes.totalAmount, true),
      availableAmountText: formatCoinAmount(buyCoinRes.symbol, buyCoinRes.availableAmount, true),
      lockAmountText: formatCoinAmount(buyCoinRes.symbol, buyCoinRes.lockAmount, true),
      positionAmountText: formatCoinAmount(buyCoinRes.symbol, buyCoinRes.positionAmount, false),
    },
    sellCoin: {
      ...sellCoinRes,
      totalAmount: formatCoinAmount(sellCoinRes.symbol, sellCoinRes.totalAmount, false),
      availableAmount: formatCoinAmount(sellCoinRes.symbol, sellCoinRes.availableAmount, false),
      lockAmount: formatCoinAmount(sellCoinRes.symbol, sellCoinRes.lockAmount, false),
      positionAmount: formatCoinAmount(sellCoinRes.symbol, sellCoinRes.positionAmount, false),
      totalAmountText: formatCoinAmount(sellCoinRes.symbol, sellCoinRes.totalAmount, true),
      availableAmountText: formatCoinAmount(sellCoinRes.symbol, sellCoinRes.availableAmount, true),
      lockAmountText: formatCoinAmount(sellCoinRes.symbol, sellCoinRes.lockAmount, true),
      positionAmountText: formatCoinAmount(sellCoinRes.symbol, sellCoinRes.positionAmount, false),
    },
  }
  const assetsStore = baseAssetsStore.getState()
  assetsStore.updateUserAssetsSpot(userAssets)
  return userAssets
}
