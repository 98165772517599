import { create } from 'zustand'
import { createTrackedSelector } from 'react-tracked'
import produce from 'immer'
import {
  CopyTradingAccountRateEnum,
  CopyTradingSettingsFollowStrategyEnum,
  CopyTradingSettingsLeverageEnum,
  CopyTradingSettingsOpenPositionEnum,
} from '@/constants/copy-trading/settings'
import {
  ICopyTradingFollowerAccountListData,
  IFollowProjectGetByIdResp,
  IFollowSettingSaveReq,
  IFollowStrategyListResp,
  ITradePairListProjectData,
} from '@/typings/api/copy-trading/settings'
import { CopyTradingBusinessConfigResp } from '@/typings/api/copy-trading/common'

type IStore = ReturnType<typeof getStore>

export const defaultCurrentProjectDetail = {} as IFollowProjectGetByIdResp

export const defaultCopyTradingBusinessConfig = {
  /** 默认单账户最大跟随金额 */
  defaultMaxAccountAmount: 5000,
  /** 默认单笔订单最大跟随金额 */
  defaultMaxSingleAmount: 1000,
  /** 最小跟单金额 */
  followMinAmount: 1,
  /** 最大跟单仓位金额 */
  maxPositionAmount: 20000,
  /** 最小跟单仓位比例 */
  minPositionRate: 0.01,
  /**  最大跟单仓位比例 */
  maxPositionRate: 20,
  followUserMax: 1,
  shareBonusRateMax: 0,
  businessId: 1,
  maxFollowersCount: 1,
  traderKyc: 'yes',
} as CopyTradingBusinessConfigResp

export interface ISettingsDetailsData extends IFollowSettingSaveReq {
  followAccount?: ICopyTradingFollowerAccountListData
  positionAmount?: number
  positionRate?: number
  strategyName?: string
}

const settingCommonDefaultInfo = {
  // 设置策略类型
  settingType: CopyTradingSettingsFollowStrategyEnum.full,
  // 划转跟随交易员 默认开启
  isFollowTransfer: 'Y',
  // 单笔订单最大跟随金额 todo 后面走配置
  singleFollowMaxAmount: 0,
  // 单账户最大跟随金额
  singleAccountMaxAmount: 0,
  // 开仓保证金方式，默认固定额度
  positionType: CopyTradingSettingsOpenPositionEnum.fixed,
  /** 跟单资金类型 */
  fundingRateType: CopyTradingAccountRateEnum.accountTotalRate,
  /** 杠杆类型 */
  // leverType: CopyTradingSettingsLeverageEnum.follow,
}

export const defaultSettingsInfo = {
  // todo 保留本地设置
  saveStrategy: 'Y',
  followAccount: {} as ICopyTradingFollowerAccountListData,
  ...settingCommonDefaultInfo,
} as ISettingsDetailsData

export const defaultStrategyDetail = {
  ...settingCommonDefaultInfo,
} as IFollowStrategyListResp

export const defaultStrategyForm = {
  [CopyTradingSettingsFollowStrategyEnum.full]: {
    leverType: CopyTradingSettingsLeverageEnum.follow,
  },
  [CopyTradingSettingsFollowStrategyEnum.free]: {
    leverType: CopyTradingSettingsLeverageEnum.specify,
    longLever: '',
    shortLever: '',
    symbolLeverList: [],
  },
  [CopyTradingSettingsFollowStrategyEnum.my]: { strategyId: '', longLever: '', shortLever: '', symbolLeverList: [] },
}

export const defaultSymbolLeverList = [] as ITradePairListProjectData[]

export const defaultFollowAccountList = [] as ICopyTradingFollowerAccountListData[]

export const defaultFollowStrategyList = [] as IFollowStrategyListResp[]

const getStore = set => ({
  /** 当前项目详情 */
  currentProjectDetail: defaultCurrentProjectDetail,
  updateCurrentProjectDetail: (val: IFollowProjectGetByIdResp) =>
    set(
      produce((state: IStore) => {
        state.currentProjectDetail = val
      })
    ),
  /** 跟单设置详情 */
  settingsInfo: defaultSettingsInfo,
  updateSettingsInfo: (val: ISettingsDetailsData) =>
    set(
      produce((state: IStore) => {
        // console.log('val----', val)
        state.settingsInfo = val
      })
    ),
  /** 跟单策略信息 */
  strategyLeverForm: defaultStrategyForm,
  updateStrategyLeverForm: val =>
    set(
      produce((state: IStore) => {
        state.strategyLeverForm = val
      })
    ),
  /** 跟单策略信息 */
  strategyForm: defaultStrategyForm,
  updateStrategyForm: val =>
    set(
      produce((state: IStore) => {
        state.strategyForm = val
      })
    ),
  /** 我的跟单账户 */
  accountList: defaultFollowAccountList,
  updateAccountList: (val: ICopyTradingFollowerAccountListData[]) =>
    set(
      produce((state: IStore) => {
        state.accountList = val
      })
    ),
  /** 带单资产，选中的带单主账户 */
  currentFollowMainAccount: {} as ICopyTradingFollowerAccountListData,
  updateCurrentFollowMainAccount: (val: ICopyTradingFollowerAccountListData) =>
    set(
      produce((state: IStore) => {
        state.currentFollowMainAccount = val
      })
    ),
  /** 高级杠杆币对列表 */
  symbolLeverList: defaultSymbolLeverList,
  updateSymbolLeverList: val =>
    set(
      produce((state: IStore) => {
        state.symbolLeverList = val
      })
    ),
  /** 商户的跟单配置 */
  copyTradingBusinessConfig: defaultCopyTradingBusinessConfig,
  updateCopyTradingBusinessConfig: (val: CopyTradingBusinessConfigResp) =>
    set(
      produce((state: IStore) => {
        state.copyTradingBusinessConfig = val
      })
    ),
  /** 跟随策略列表 */
  followStrategyList: defaultFollowStrategyList,
  updateFollowStrategyList: (val: IFollowStrategyListResp[]) =>
    set(
      produce((state: IStore) => {
        state.followStrategyList = val
      })
    ),
  /** 策略详情 */
  strategyDetail: defaultStrategyDetail as IFollowStrategyListResp,
  updateStrategyDetail: (val: IFollowStrategyListResp) =>
    set(
      produce((state: IStore) => {
        state.strategyDetail = val
      })
    ),
  /** 选中的策略列表 */
  // followStrategyList: defaultFollowStrategyList,
  // updateFollowStrategyList: (val: IFollowStrategyListResp[]) =>
  //   set(
  //     produce((state: IStore) => {
  //       state.followStrategyList = val
  //     })
  //   ),
})

const baseCopyTradingSettingsStore = create(getStore)

const useCopyTradingSettingsStore = createTrackedSelector(baseCopyTradingSettingsStore)

export { useCopyTradingSettingsStore, baseCopyTradingSettingsStore }
