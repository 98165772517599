import { getOverviewAsset } from '@/apis/assets/common'
import { rateFilter } from './common'

/**
 * 获取资产总览
 */
export const getAssetsOverview = async () => {
  const res = await getOverviewAsset({})
  const { isOk, data } = res || {}
  if (!isOk || !data) {
    return null
  }
  return data
}

/**
 * ws 资产回调数据处理，更新资产列表
 * @param assetList
 * @param data
 * @returns
 */
export const getAssetListWS = (assetList, data) => {
  try {
    const { coinId, balance, locked, total } = data

    // 更新变更的资产信息
    const newAssetList =
      assetList &&
      assetList.map((item: any) => {
        // coinId 是 bigInt 类型，转成字符串比对
        if (`${coinId}` === `${item.coinId}`) {
          item = {
            ...item,
            availableAmount: balance,
            lockAmount: locked,
            totalAmount: total,
            usdBalance: rateFilter({
              symbol: item.symbol,
              amount: total,
              showUnit: false,
              rate: `USD`,
            }), // 资产更新后，计算 usdBalance 值（折合美元金额，过滤小额资产处用）
          }
        }
        return item
      })

    return newAssetList
  } catch (error) {
    return null
  }
}

/** 去除重复的数据，交易页下单成功 ws 回调会有多条数据且有重复的情况 */
export function removeRepeatData(data) {
  if (!Array.isArray(data)) {
    return data
  }
  for (let i = 0; i < data.length; i += 1) {
    for (let j = i + 1; j < data.length; ) {
      if (data[i].coinId === data[j].coinId) {
        data.splice(j, 1)
      } else {
        j += 1
      }
    }
  }
  return data
}
