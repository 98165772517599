import request, { MarkcoinRequest } from '@/plugins/request'
import {
  FuturesAccountListResp,
  FuturesAssetsListReq,
  FuturesAssetsListResp,
  FuturesDetailMarginListReq,
  FuturesDetailMarginListResp,
  FuturesListReq,
} from '@/typings/api/assets/futures'

/**
 * 获取带单主账户的资产列表
 * https://yapi.nbttfc365.com/project/100/interface/api/19963
 */
export const getAssetsTakerAccountList: MarkcoinRequest<FuturesListReq, FuturesAccountListResp> = params => {
  return request({
    path: `/v1/perpetual/account/taker/overview`,
    method: 'GET',
    params,
  })
}

/**
 * 获取带单资产列表 - 总览
 * https://yapi.nbttfc365.com/project/100/interface/api/20005
 */
export const getTakerAccountAssetsList: MarkcoinRequest<FuturesAssetsListReq, FuturesAssetsListResp> = params => {
  return request({
    path: `/v1/perpetual/follow/assets/taker/list`,
    method: 'GET',
    params,
  })
}
