import request, { MarkcoinRequest } from '@/plugins/request'
import {
  FuturesAccountListResp,
  FuturesDetailMarginListReq,
  FuturesDetailMarginListResp,
  FuturesListReq,
} from '@/typings/api/assets/futures'

/**
 * 获取跟单主账户的资产列表
 * https://yapi.nbttfc365.com/project/100/interface/api/19969
 */
export const getAssetsFollowerAccountList: MarkcoinRequest<FuturesListReq, FuturesAccountListResp> = params => {
  return request({
    path: `/v1/perpetual/account/follower/overview`,
    method: 'GET',
    params,
  })
}

/**
 * 获取跟单子账户的资产列表 - 子账户币种资产列表
 * https://yapi.nbttfc365.com/project/100/interface/api/19993
 */
export const getFollowerAccountAssetsList: MarkcoinRequest<
  FuturesDetailMarginListReq,
  FuturesDetailMarginListResp
> = params => {
  return request({
    path: `/v1/perpetual/follow/assets/follower/list`,
    method: 'GET',
    params,
  })
}
